//病患随访记录
<template>
	<div>
		<el-row class="bread_crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/PatientManagement' }">病患管理</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/PatientManagement' }">病患信息</el-breadcrumb-item>
				<el-breadcrumb-item v-if="isFlag">随访列表</el-breadcrumb-item>
				<el-breadcrumb-item v-if="!isFlag">
					<span @click="isFlagChange">随访信息</span>
				</el-breadcrumb-item>
				<el-breadcrumb-item v-if="!isFlag&&typeVal=='新增'">新增随访信息</el-breadcrumb-item>
				<el-breadcrumb-item v-if="!isFlag&&typeVal=='编辑'">编辑随访信息</el-breadcrumb-item>
			</el-breadcrumb>
		</el-row>

		<template v-if="isFlag">
			<div class="public_bg">
				<el-row type="flex" justify="end">
					<el-button text-align="center" type="primary" @click="NewAdd('新增',{})">OCR新增</el-button>
					<el-button text-align="center" type="primary" @click="NewAdd('新增',{},1)">手动新增</el-button>
				</el-row>
				<el-row class="column_info">
					<div>病历号:{{medicalData.medicalNo}}</div>
					<div>手机号码:{{medicalData.phoneNumber}}</div>
					<div>姓名:{{medicalData.fullName}}</div>
				</el-row>
				<!-- <table class="table-sty" cellspacing="0" cellpadding="0">
			  <tr>
			    <td>病历号</td>
			    <td>{{medicalData.medicalNo}}</td>
			    <td>手机号码</td>
			    <td>{{medicalData.phoneNumber}}</td>
				<td>姓名</td>
				<td>{{medicalData.fullName}}</td>
			  </tr>
			</table> -->
				<div class="tableMargin" style="margin-top: 20px;">
					<el-table :height="Height" stripe ref="multipleTable" :header-cell-style="{background:'#FAFAFA'}" :data="PatientsFollowQueryPageData"
					 style="width: 100%">
						<el-table-column align="left" prop="num" label="序号">
						</el-table-column>
						<el-table-column align="left" prop="followPerson" label="随访人">
						</el-table-column>
						<el-table-column align="left" prop="followDate" label="随访日期">
							<template slot-scope="scope">
								{{scope.row.followDate}}
							</template>
						</el-table-column>
						<el-table-column align="left" prop="againFollowDate" label="下次随访日期">
							<template slot-scope="scope">
								{{scope.row.againFollowDate}}
							</template>
						</el-table-column>
						<el-table-column align="left" prop="creationDate" label="创建时间">
							<template slot-scope="scope">
								{{scope.row.creationDate}}
							</template>
						</el-table-column>
						<el-table-column align="left" prop="updateType" label="修改类型">
							<template slot-scope="scope">
								{{scope.row.updateType}}
							</template>
						</el-table-column>
						<el-table-column align="left" prop="errorMessage" label="文件状态">
							<template slot-scope="scope">
								{{scope.row.errorMessage}}
							</template>
						</el-table-column>
						<el-table-column align="left" prop="reportDocument" label="文件报告">
							<template slot-scope="scope">
								{{scope.row.reportDocument}}
							</template>
						</el-table-column>
						<el-table-column align="left" label="操作">
							<template slot-scope="scope">
								<el-button text-align="center" :type="scope.row.businessStatus=='1' ? 'primary' : 'warning'" @click="NewAdd('编辑',scope.row)">
									{{scope.row.businessStatus=="1" ? "编辑" : "查看信息"}}
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<v-paging v-on:childPage="pages" :total=total></v-paging>
			</div>
		</template>
		<template v-if="!isFlag">
			<div class="public_no">
				<el-card class="box-card margin_top">
					<div slot="header" class="clearfix" @click="dropDown('随访记录')">
						<span>随访记录</span>
						<span class="scaling">
							{{furecords ? '收回' : '展开'}}
							<i :class="furecords ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
						</span>
					</div>
					<el-form v-if="furecords" ref="form" label-position="right" label-width="120px" :model="form" :rules="rules">
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label-width="120px" label="随访人:" prop="followPerson">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form.followPerson"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="随访日期:" prop="followDate">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form.followDate"
									 @input="changeinput($event)"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="下次随访日期:" prop="againFollowDate">
									<el-date-picker clearable :disabled="businessStatus=='2' ? true : false" value-format="yyyy-MM-dd HH:mm:ss"
									 type="datetime" placeholder="请选择日期" v-model="form.againFollowDate" style="width: 100%;"></el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="14">
								<el-form-item label-width="120px" label="随访时临床情况:" prop="followInfo">
									<el-input type="textarea" :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form.followInfo"
									 @input="changeinput($event)"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="10">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="24">
								<el-form-item label="检查报告:">
									<template v-if="fileStatus=='1'">
										<el-button v-if="medicalNo&&(businessStatus=='1') " type="primary" @click="handles()">查看随访报告</el-button>
									</template>
								</el-form-item>
								<div style="margin-left: 120px;color: #1890FF;" @click="handles()">{{this.form.reportDocument}}</div>
								<!-- <el-button style="margin-left: 120px;"  type="text" @click="handles()">{{this.form.reportDocument}}</el-button> -->
							</el-col>
							<!-- <el-col :span="18">
					</el-col> -->
							<!-- <el-col :span="6">
						<el-form-item label-width="120px" label="文件报告" prop="reportDocument">
							<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入文件报告" clearable v-model="form.reportDocument" @input="changeinput($event)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<template  >
						<el-form-item v-if="fileStatus=='1'">
							<el-button  v-if="medicalNo&&(businessStatus=='1') " type="primary"  @click="uploads">查看随访报告</el-button>
						</el-form-item>
						</template>
					</el-col>
					<el-col :span="6">
						<el-form-item>
							<el-button  type="primary" @click="handles()">查看随访报告</el-button>
						</el-form-item>
					</el-col> -->
						</el-row>
					</el-form>
				</el-card>
				<!-- <el-card class="box-card margin_top">
			<div slot="header" class="clearfix">
				<span>患者基本信息</span>
			</div>
			<el-form ref="form2" label-position="right" label-width="100px" :model="form2" :rules="rules2">
				<el-row type="flex" justify="space-around">
					<el-col :span="6">
						<el-form-item v-if="types=='200030'" label="医院" prop="hospitalName">
							<el-input clearable :disabled="true" v-model="form2.hospitalName" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item v-if="types=='200030'" label="科室" prop="departmentsName">
							<el-input  clearable :disabled="true" v-model="form2.departmentsName" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item v-if="types=='200030'" label="医生" prop="userName">
							<el-input  clearable :disabled="true" v-model="form2.userName" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" justify="space-around">
					<el-col :span="6">
						<el-form-item label="病历号" prop="medicalNo">
							<el-input clearable :disabled="true" placeholder="请输入病历号" v-model="form2.medicalNo"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="手机号" prop="phoneNumber">
							<el-input clearable placeholder="请输入手机号" v-model="form2.phoneNumber"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
                    </el-col>
				</el-row>
				<el-row type="flex" justify="space-around" align="middle">
					<el-col :span="6">
						<el-form-item label="姓名" prop="fullName">
							<el-input clearable placeholder="请输入姓名" v-model="form2.fullName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="年龄" prop="age">
							<el-input clearable placeholder="请输入年龄" v-model="form2.age"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="性别" prop="gender">
							<el-select filterable  clearable v-model="form2.gender" placeholder="请选择性别" class="select-sty">
								<el-option
								  v-for="item in SexOptions"
								  :key="item.no"
								  :label="item.label"
								  :value="item.no">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row type="flex" justify="space-around">
					<el-col :span="6">
						<el-form-item label="住址" prop="Provinces">
							<el-cascader  v-model="form2.Provinces" clearable style="width:100%" placeholder="请选择" filterable
							 :props="{ value: 'id', label: 'executiveName'}" :options="ProvincesOptions">
							</el-cascader>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label-width="1px"  label="" prop="address">
							<el-input clearable v-model="form2.address" placeholder="请输入详细住址" ></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">

					</el-col>
				</el-row>
			</el-form>
		</el-card> -->
				<el-card class="box-card margin_top">
					<div slot="header" class="clearfix" @click="dropDown('产品信息')">
						<span>产品信息</span>
						<span class="scaling">
							{{cpval ? '收回' : '展开'}}
							<i :class="cpval ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
						</span>
					</div>
					<el-form v-if="cpval" ref="form3" label-position="right" label-width="120px" :model="form3" :rules="rules3">
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="产品厂商:" prop="ManufacturerModel">
									<el-cascader filterable :disabled="typeVal=='编辑'" :options="ManufacturerModelOptions" clearable v-model="form3.ManufacturerModel"
									 style="width:100%"></el-cascader>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="电极型号:" prop="markingList">
								  <el-cascader filterable :props="{ value: 'no', label: 'label'}" :options="ManufacturerMarkingOptions" clearable v-model="form3.markingList" style="width:100%"></el-cascader>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<!-- <el-form-item label="产品型号" prop="productType">
							<el-select clearable filterable v-model="form3.productType" placeholder="请选择产品型号" class="select-sty">
								<el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item> -->
								<el-form-item label="类型:" prop="productType">
									<el-input :disabled="typeVal=='编辑'" placeholder="请输入" clearable v-model="form3.productType" @input="changeinput($event)"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="产品序列号:" prop="productSequenceNumber">
									<el-input :disabled="typeVal=='编辑'" placeholder="请输入" clearable v-model="form3.productSequenceNumber" @input="changeinput($event)"></el-input>
								</el-form-item>
							</el-col>
							
						</el-row>
					</el-form>
				</el-card>
				<el-card class="box-card margin_top">
					<div slot="header" class="clearfix" @click="dropDown('本次随访测试数据')">
						<span>本次随访测试数据</span>
						<span class="scaling">
							{{bcsfcssjval ? '收回' : '展开'}}
							<i :class="bcsfcssjval ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
						</span>
					</div>
					<el-form v-if="bcsfcssjval" ref="form6" label-position="right" label-width="100px" :model="form6" :rules="rules6">
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label-width="100px" label="心房感知:" prop="atrialPerception">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.atrialPerception"
									 @input="changeinput($event)">
										<template slot="append">mv</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="阈值:" prop="atrialThreshold">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.atrialThreshold"
									 @input="changeinput($event)">
										<template slot="append">v/ms</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="阻抗:" prop="atrialImpedance">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.atrialImpedance"
									 @input="changeinput($event)">
										<template slot="append">欧姆</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label-width="100px" label="右心室感知:" prop="rightVentricularPerception">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.rightVentricularPerception"
									 @input="changeinput($event)">
										<template slot="append">V</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="阈值:" prop="rightVentricularThreshold">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.rightVentricularThreshold"
									 @input="changeinput($event)">
										<template slot="append">v/ms</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="阻抗:" prop="rightVentricularImpedance">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.rightVentricularImpedance"
									 @input="changeinput($event)">
										<template slot="append">欧姆</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label-width="80px" label="高压阻抗:" prop="rightVentricularHighVoltageImpedance">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.rightVentricularHighVoltageImpedance"
									 @input="changeinput($event)">
										<template slot="append">欧姆</template>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="左心室1阈值:" prop="leftVentricularThresholdA">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.leftVentricularThresholdA"
									 @input="changeinput($event)">
										<template slot="append">v/ms</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="阻抗:" prop="leftVentricularImpedanceA">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.leftVentricularImpedanceA"
									 @input="changeinput($event)">
										<template slot="append">欧姆</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
							<el-col :span="6">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="左心室2阈值:" prop="leftVentricularThresholdB">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.leftVentricularThresholdB">
										<template slot="append">v/ms</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="阻抗:" prop="leftVentricularImpedanceB">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form6.leftVentricularImpedanceB">
										<template slot="append">欧姆</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
							<el-col :span="6">
							</el-col>
						</el-row>
					</el-form>
				</el-card>

				<el-card class="box-card margin_top">
					<div slot="header" class="clearfix" @click="dropDown('除颤分区设置')">
						<span>除颤分区设置</span>
						<span class="scaling">
							{{ccfqszval ? '收回' : '展开'}}
							<i :class="ccfqszval ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
						</span>
					</div>
					<el-form v-if="ccfqszval" ref="form5" label-position="right" label-width="120px" :model="form5" :rules="rules5">
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="VF:" prop="vf">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form5.vf"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="VT:" prop="vtA">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form5.vtA"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="VT1:" prop="vtB">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form5.vtB"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="VT2:" prop="vtC">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form5.vtC"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</el-card>
				<el-card class="box-card margin_top">
					<div slot="header" class="clearfix" @click="dropDown('参数设置')">
						<span>参数设置</span>
						<span class="scaling">
							{{csszval ? '收回' : '展开'}}
							<i :class="csszval ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
						</span>
					</div>
					<el-form v-if="csszval" ref="form4" label-position="right" label-width="120px" :model="form4" :rules="rules4">
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="工作模式:" prop="workingMode">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.workingMode"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="起搏AV:" prop="pacingAv">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.pacingAv"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="电池寿命:" prop="batteryLife">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.batteryLife"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="报警:" prop="callThePolice">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.callThePolice"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around" align="middle">
							<el-col :span="6">
								<el-form-item label="基本频率:" prop="basicFrequency">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.basicFrequency">
										<template slot="append">bpm</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="感知AV:" prop="avPerception">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.avPerception"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="治疗:" prop="treatment">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.treatment"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around" align="middle">
							<el-col :span="6">
								<el-form-item label="上限频率:" prop="upperLimitFrequency">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.upperLimitFrequency">
										<template slot="append">bpm</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="V-V间期:" prop="vvInterval">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.vvInterval"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="充电时间:" prop="chargingTime">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.chargingTime"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around" align="middle">
							<el-col :span="6">
								<el-form-item label="休息频率:" prop="restFrequency">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.restFrequency">
										<template slot="append">bpm</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="AP%:" prop="ap">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.ap"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="AF%:" prop="ap">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.af"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">

							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="滞后频率:" prop="lagFrequency">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.lagFrequency">
										<template slot="append">bpm</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="BP%:" prop="bp">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.bp"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="VP%:" prop="vp">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.vp"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="心房脉冲振幅:" prop="atrialPulseAmplitude">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.atrialPulseAmplitude">
										<template slot="append">V</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="脉宽:" prop="atrialPulseWidth">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.atrialPulseWidth">
										<template slot="append">ms</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="感知灵敏度:" prop="atrialSensitivity">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.atrialSensitivity"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="阈值管理:" prop="atrialThresholdManagement">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.atrialThresholdManagement"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="右心室脉冲振幅:" prop="rightVentricularPulseAmplitude">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.rightVentricularPulseAmplitude">
										<template slot="append">V</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="脉宽:" prop="rightVentricularPulseWidth">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.rightVentricularPulseWidth">
										<template slot="append">ms</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="感知灵敏度:" prop="rightVentricularSensitivity">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.rightVentricularSensitivity"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="阈值管理:" prop="rightVentricularThresholdManagement">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.rightVentricularThresholdManagement"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label-width="130px" label="左心室1脉冲振幅:" prop="leftVentricularPulseAmplitudea">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.leftVentricularPulseAmplitudea">
										<template slot="append">V</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="脉宽:" prop="leftVentricularPulseWidtha">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.leftVentricularPulseWidtha">
										<template slot="append">ms</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
							<el-col :span="6">
								<el-form-item label="阈值管理:" prop="leftVentricularThresholdManagementa">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.leftVentricularThresholdManagementa"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label-width="130px" label="左心室2脉冲振幅:" prop="leftVentricularPulseAmplitudeb">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.leftVentricularPulseAmplitudeb">
										<template slot="append">V</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="脉宽:" prop="leftVentricularPulseWidthb">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form4.leftVentricularPulseWidthb">
										<template slot="append">ms</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
							<el-col :span="6">
								<el-form-item label="阈值管理:" prop="leftVentricularThresholdManagementb">
									<el-input :disabled="businessStatus=='2' ? true : false" placeholder="请输入" clearable v-model="form4.leftVentricularThresholdManagementb"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</el-card>
				<el-card class="box-card margin_top">
					<div slot="header" class="clearfix" @click="dropDown('参数调整')">
						<span>参数调整</span>
						<span class="scaling">
							{{cstzval ? '收回' : '展开'}}
							<i :class="cstzval ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
						</span>
					</div>
					<el-form v-if="cstzval" ref="form7" label-position="right" label-width="130px" :model="form7" :rules="rules7">
						<el-row type="flex" justify="space-around" align="middle">
							<el-col :span="6">
								<el-form-item label="左室向量是否改变:" prop="leftVentricularVector">
									<el-select :disabled="businessStatus=='2' ? true : false" filterable clearable v-model="form7.leftVentricularVector"
									 placeholder="请选择" class="select-sty">
										<el-option v-for="item in leftVentricularVectorOptions" :key="item.no" :label="item.label" :value="item.no">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label-width="150px" label="AdaptaCRT功能打开:" prop="leftAdaptacrtFunction">
									<el-select :disabled="businessStatus=='2' ? true : false" filterable clearable v-model="form7.leftAdaptacrtFunction"
									 placeholder="请选择" class="select-sty">
										<el-option v-for="item in leftAdaptacrtFunctionOptions" :key="item.no" :label="item.label" :value="item.no">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="MPP功能打开:" prop="leftMppFunction">
									<el-select :disabled="businessStatus=='2' ? true : false" filterable clearable v-model="form7.leftMppFunction"
									 placeholder="请选择" class="select-sty">
										<el-option v-for="item in leftMppFunctionOptions" :key="item.no" :label="item.label" :value="item.no">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="6">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="6">
								<el-form-item label="自身心律:" prop="selfRhythm">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入" v-model="form7.selfRhythm">
										<template slot="append">bpm</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="心室自身优先功能VIP:" label-width="150px" prop="leftVentricularVector">
									<el-select :disabled="businessStatus=='2' ? true : false" filterable clearable v-model="form7.ventricleVip"
									 placeholder="请选择" class="select-sty">
										<el-option v-for="item in ventricleVip" :key="item.no" :label="item.label" :value="item.no">
										</el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12">
							</el-col>
						</el-row>
						<el-row type="flex" justify="space-around">
							<el-col :span="14">
								<el-form-item label-width="130px" label="备注:" prop="followInfo">
									<el-input type="textarea" maxlength="1000" :disabled="businessStatus=='2' ? true : false" placeholder="请输入"
									 clearable v-model="form.remark"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="10">
							</el-col>
						</el-row>
					</el-form>
				</el-card>
				<el-card class="box-card margin_top">
					<div slot="header" class="clearfix" @click="dropDown('事件')">
						<span>VT/VF事件</span>
						<span class="scaling">
							{{vvsjval ? '收回' : '展开'}}
							<i :class="vvsjval ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
						</span>
					</div>
					<template v-if="vvsjval">
						<el-row type="flex" justify="center">
							<el-col :span="24">
								<el-button icon="el-icon-plus" type="primary" v-if="businessStatus=='1'" @click.prevent="addRow()">新增</el-button>
								<el-button v-if="businessStatus=='1'">刷新</el-button>
							</el-col>
						</el-row>
						<el-table stripe :data="vtfIdData" :header-cell-style="{background:'#FAFAFA'}" ref="table" tooltip-effect="dark"
						 border stripe style="width: 93%;margin-top: 20px;">
							<el-table-column label="日期时间" align="left">
								<template slot-scope="scope">
									<el-date-picker :disabled="businessStatus=='2' ? true : false" value-format="yyyy-MM-dd HH:mm:ss" clearable
									 type="date" placeholder="请选择日期时间" v-model="scope.row.vtfDateTime" style="width: 100%;"></el-date-picker>
								</template>
							</el-table-column>
							<el-table-column label="类型">
								<template slot-scope="scope">
									<el-select :disabled="businessStatus=='2' ? true : false" filterable clearable v-model="scope.row.vtfType"
									 placeholder="请选择" class="select-sty">
										<el-option v-for="item in upgradeOptions" :key="item.no" :label="item.label" :value="item.no">
										</el-option>
									</el-select>
								</template>
							</el-table-column>
							<el-table-column label="频率">
								<template slot-scope="scope">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入频率" class="remark" v-model="scope.row.vtfFrequency"></el-input>
								</template>
							</el-table-column>
							<el-table-column label="持续时间">
								<template slot-scope="scope">
									<el-input clearable :disabled="businessStatus=='2' ? true : false" placeholder="请输入持续时间" class="remark"
									 v-model="scope.row.vtfDuration"></el-input>
								</template>
							</el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button type="text" v-if="businessStatus=='1'" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<el-row type="flex" style="margin-top: 20px;">
							<el-col :span="24">
								<el-button type="primary" v-if="businessStatus=='1'" @click="saves">保存</el-button>
								<el-button v-if="businessStatus=='1'" @click="cancle">取消</el-button>
								<el-button @click='cancle' v-if="businessStatus=='2'">返回</el-button>
							</el-col>
						</el-row>
					</template>
				</el-card>

			</div>
		</template>
		<!-- 上传检查报告模态框 -->
		<el-dialog title="提示" :visible.sync="dialogVisible" width="40%">
			<el-row type="flex" align="middle">
				<span style="margin-right: 20px;">文件路径</span>
				<el-upload :auto-upload="false" :on-change="handleChange" class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
				 :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="1" :on-exceed="handleExceed"
				 :file-list="fileList">
					<el-button size="small" type="primary">选择文件:</el-button>
				</el-upload>
			</el-row>
			<span slot="footer" class="dialog-footer" style="text-align: center; ">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisibleSave">上传</el-button>
			</span>
		</el-dialog>
		<!-- 倒计时 -->
		<el-dialog @close='closeDialog' title="" :visible.sync="countdownVisible" width="30%">
			<span ref="startTimer"></span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="countdownVisible = false">取 消</el-button>
				<el-button type="primary" @click="closeDialog">关闭</el-button>
			</span>
		</el-dialog>
		<!--新增模态框  -->
		<el-dialog title="提示" :visible.sync="DialogChangeVisible" width="40%">
			<el-form :model="ruleFormChange" :rules="rulesDelete" ref="ruleFormChange" label-width="120px" class="demo-ruleForm">
				<el-form-item label="医院科室医生" prop="ModelList">
					<el-cascader  ref="refSubCat" v-model="ruleFormChange.ModelList" clearable style="width:100%" placeholder="请选择"
					 filterable :props="{ value: 'no', label: 'label'}" :options="CurrencyAllOptions">
					</el-cascader>
				</el-form-item>
				<span style="color:red;margin-left:120px">请谨慎选择</span>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="DialogChangeVisible = false">取 消</el-button>
				<el-button type="primary" @click="DialogChangeVisibleSave">确 认</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		beforeDestroy() {
			this.timer = clearTimeout(this.timer);
			this.flag = clearInterval(this.flag);
			this.ocrName = localStorage.removeItem("ocrName1");
		},
		methods: {
			handleAddressFun: function(e,form,thsAreaCode){
			// thsAreaCode = this.form.areaCode    // 注意1：获取value值
				thsAreaCode = this.$refs['cascaderAddr'].currentLabels    //注意2： 获取label值
				alert(thsAreaCode)  // 注意3： 最终结果是个一维数组对象

			},
			isFlagChange() {
				this.isFlag = true;
			},
			handles() {
				let url = this.oss;
				if (url) {
					window.open(url, '_blank')
				} else {
					this.$message.warning("暂无网络地址");
				}
			},
			viewPdf() {
				this.cpval = true;
				this.bcsfcssjval = true;
				this.ccfqszval = true;
				this.csszval = true;
				this.cstzval = true;
				this.vvsjval = true;
				this.furecords = true;
			},
			//查询医院科室医生级联
			async QueryCurrencyAll() {
				let menuId = JSON.parse(localStorage.getItem('menuList'))
				let obj = {
					menuId: menuId.PatientManagement,
				}
				let res = await this.$Http.CurrencyAll(obj);
				if (res.code == '200') {
					this.CurrencyAllOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			closeDialog() {
				this.countdownVisible = false;
				this.flag = clearInterval(this.flag);
				this.timer = clearTimeout(this.timer);
				this.hour = 0;
				this.minutes = 0;
				this.seconds = 0;
				this.counts = 1;
			},
			startTimer() {
				this.seconds += 1;
				if (this.seconds >= 60) {
					this.seconds = 0;
					this.minutes = this.minutes + 1;
				}

				if (this.minutes >= 60) {
					this.minutes = 0;
					this.hour = this.hour + 1;
				}
				this.$refs.startTimer.innerHTML = "文件处理时长" + (this.minutes < 10 ? '0' + this.minutes : this.minutes) + ':' + (this.seconds <
					10 ? '0' + this.seconds : this.seconds) + "已请求:" + this.counts + "次";
			},
			// 上传检查报告
			uploads() {
				this.dialogVisible = true;
				this.fileParam = {};
				this.fileList = [];
			},

			//厂商型号
			//厂商型号
			async QueryManufacturerModel(){
				let menuId=JSON.parse(localStorage.getItem('menuList'))
				let obj={
					menuId: menuId.PatientManagement,
				}
			    let res = await this.$Http.ManufacturerModel(obj);
				if (res.code == '200') {
					this.ManufacturerModelOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//性别接口
			async QuerySexType() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100002"
				});
				if (res.code == '200') {
					this.SexOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//码值接口
			async QueryCodeValue() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100006"
				});
				if (res.code == '200') {
					this.leftVentricularVectorOptions = res.data; //左心室是否改变
					this.ventricleVip = res.data; //心室自身优先功能VIP
					this.leftAdaptacrtFunctionOptions = res.data; //adaptacrt功能打开
					this.leftMppFunctionOptions = res.data; //mpp功能打开
				} else {
					this.$message.error(res.message);
				}
			},
			//省市区接口
			async QueryProvinces() {
				let res = await this.$Http.Provinces({});
				if (res.code == '200') {
					this.ProvincesOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
			//下拉
			dropDown(val) {
				if (val == "产品信息") {
					this.cpval = !this.cpval;
				} else if (val == "本次随访测试数据") {
					this.bcsfcssjval = !this.bcsfcssjval;
				} else if (val == "除颤分区设置") {
					this.ccfqszval = !this.ccfqszval;
				} else if (val == "参数设置") {
					this.csszval = !this.csszval;
				} else if (val == "参数调整") {
					this.cstzval = !this.cstzval;
				} else if (val == "事件") {
					this.vvsjval = !this.vvsjval;
				} else if (val == "随访记录") {
					this.furecords = !this.furecords;

				}
			},
			changeinput() {
				this.$forceUpdate()
			},
			handleChange(file, fileList) {
				let excelfileExtend = ".PDF,.pdf" //设置文件格式
				let fileExtend = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
				if (excelfileExtend.indexOf(fileExtend) <= -1) {
					this.$refs.upload.clearFiles();
					this.$message.error('文件格式错误')
					return false
				}
				this.fileParam = file.raw;
				this.inspectionReportName = file.name;
				this.fileType = "1";
			},
			//上传文件
			UploadFile() {
				this.dialogVisible = true;
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
				this.fileParam = {};
				this.inspectionReportName = "";
				this.fileType = "2";
			},
			handlePreview(file) {
				console.log(file);
			},
			handlePreview(file) {
				console.log(file);
			},
			handleExceed(files, fileList) {
				this.$message.warning(`只能上传一个文件!`);
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			handleDelete(index) {
				this.vtfIdData.splice(index, 1)
			},
			// 增加行
			addRow() {
				var list = {
					rowNum: this.rowNum,
					post_id: [],
					require_des: '',
					remark: ''
				};
				this.vtfIdData.push(list)
				this.rowNum += 1;
			},
			getCodeCount() {
				let _this = this
				this.timer = setTimeout(() => {
					_this.QueryPatientsinspectionReport();
					this.counts += 1;
				}, 180000)
			},
			//新增上传PDF
			async QueryPatientsinspectionReport() {
				clearTimeout(this.timer)
				this.ocrName = localStorage.getItem("ocrName1");
				this.refSubCat = {
					departmentsId: this.medicalData.departmentsId,
					hospitalId: this.medicalData.hospitalId,
					userId: this.medicalData.userId
				}
				let info = {
					fileParam: this.fileParam,
					ocrName: this.ocrName || "", //this.form.medicalNo
					...this.refSubCat
				}
				console.log(info, "info")
				let res = await this.$Http.PatientsinspectionReport(info, true);
				if (res.code == '200') {
					// 检查报告显示
					this.dialogVisible = false;
					this.ocrName = res.data.ocrName;
					localStorage.setItem("ocrName1", res.data.ocrName);
					this.oss = res.data.patientsProductInfo.inspectionReportUrl;
					this.local = res.data.patientsProductInfo.inspectionReportLocalUrl;
					this.fileName = res.data.patientsProductInfo.inspectionReportName;
					this.inspectionReportId = res.data.patientsProductInfo.inspectionReportId;
					if (res.data.productParametersBasicInsertDto) {
						this.form4 = res.data.productParametersBasicInsertDto; //参数设置
					}
					if (res.data.productPartitionSettingsInsertDto) {
						this.form5 = res.data.productPartitionSettingsInsertDto; //除颤分区设置vtfEventInsertDto
					}
					if (res.data.parameterSettingInsertDto) {
						this.form6 = res.data.parameterSettingInsertDto; //本次随访测试数据parameterSettingInsertDto
					}
					if (res.data.patientsFollowInsertDto) {
						this.form = res.data.patientsFollowInsertDto; //随访记录
					}
					if (res.data.vtfEventInsertDto) {
						this.vtfIdData = res.data.vtfEventInsertDto; //VT
					}
					if (res.data.patientsProductInfo) {
						this.form3 = res.data.patientsProductInfo;
					}
					if (res.data.patientsProductInfo.manufacturerId && res.data.patientsProductInfo.manufacturerProductId) {
						this.form3.ManufacturerModel = [res.data.patientsProductInfo.manufacturerId, res.data.patientsProductInfo.manufacturerProductId];
					} else if(res.data.patientsProductInfo.manufacturerId){
						this.form3.ManufacturerModel = [res.data.patientsProductInfo.manufacturerId];
					}else{
						this.form3.ManufacturerModel = []
					}
					this.errorCode = res.data.patientsProductInfo.errorCode;
					if (res.data.patientsProductInfo.errorCode !== "0") {
						if (res.data.patientsProductInfo.errorCode == "-20") {
							this.$message.success(res.data.patientsProductInfo.errorMessage);
							this.getCodeCount();
						} else {
							this.$message.error(res.data.patientsProductInfo.errorMessage);
						}

					} else {
						clearTimeout(this.timer);
						this.countdownVisible = false;
						this.flag = clearInterval(this.flag)
						this.$message.success(res.data.patientsProductInfo.message);
					}
				} else {
					this.$message.error(res.message);
				}
			},
			// 保存上传
			dialogVisibleSave() {
				this.ocrName = localStorage.removeItem("ocrName1");
				this.QueryPatientsinspectionReport();
			},
			// 上传检查报告
			uploads() {
				this.dialogVisible = true;
				this.fileParam = {};
				this.fileList = [];
			},
			//分页组件
			pages(current, size) {

			},
			//查看该医生的都患者
			CheckPatient() {
				this.$router.push({
					path: '/DoctorsPatient'
				})
			},
			// 搜索
			querys() {

			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 选中事件
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			//查询随访记录
			async QueryPatientsFollowInfoBasic(dataCodeVice) {
				let info = {
					dataCodeVice: dataCodeVice
				}
				let res = await this.$Http.PatientsFollowInfoBasic(info)
				if (res.code == '200') {
					this.oss = res.data.patientsProductInfoVo.inspectionReportUrl;
					this.form3 = res.data.patientsProductInfoVo; //产品信息
					if (res.data.patientsProductInfoVo.manufacturerId && res.data.patientsProductInfoVo.manufacturerProductId) {
						this.form3.ManufacturerModel = [res.data.patientsProductInfoVo.manufacturerId,res.data.patientsProductInfoVo.manufacturerProductId
						];
					} else {
						this.form3.ManufacturerModel = [res.data.patientsProductInfoVo.manufacturerId];
					}
					this.form4 = res.data.productParametersBasicVo; //参数设置
					this.form5 = res.data.productPartitionSettingsVo; //除颤分区设置
					this.form6 = res.data.parameterSettingVo; //本次随访测试数据
					this.form7 = res.data.parameterAdjustmentVo; //参数调整
					this.form = res.data.patientsFollowVo; //随访记录
					this.vtfIdData = res.data.vtfEventVos; //VT/VF
					this.form2 = this.medicalData; //随访记录
					this.form2 = res.data.patientsInfoBasicVo;
					this.form2.Provinces = [res.data.patientsInfoBasicVo.province, res.data.patientsInfoBasicVo.city];
					
					this.refSubCat = {
						departmentsId: res.data.patientsProductInfoVo.departmentsId,
						hospitalId: res.data.patientsProductInfoVo.hospitalId,
						userId: res.data.patientsProductInfoVo.userId
					}
					this.fileStatus = res.data.patientsProductInfoVo.fileStatus
				} else {
					this.$message.error(res.message);
				}
			},
			//新增随访记录
			async QueryPatientsInfoFollowInsert() {
				//产品信息数据处理
				if (!this.form3.ManufacturerModel) {
					this.form3.ManufacturerModel = [];
				}
				if ((this.fileName == null || this.fileName.length <= 0) && this.form.reportDocument != null) {
					this.fileName = this.form.reportDocument;
				}
				let patientsProductInfo = {
					...this.form3,
					manufacturerId: this.form3.ManufacturerModel.length > 1 ? this.form3.ManufacturerModel[0] : "",
					manufacturerProductId: this.form3.ManufacturerModel.length >= 2 ? this.form3.ManufacturerModel[1] : "",
					...this.refSubCat,
					fileStatus: this.fileFile == "1" ? "2" : "1",
					inspectionReportName: this.fileName,
					inspectionReportUrl: this.oss,
					departmentsId: this.medicalData.departmentsId,
					hospitalId: this.medicalData.hospitalId,
					userId: this.medicalData.userId
				}
				let patientsFollowInsertDto = {
					...this.form,
					...this.form2
				}
				let info = {
					patientsFollowInsertDto: patientsFollowInsertDto, //随访记录parameterAdjustmentInsertDto
					productParametersBasicInsertDto: this.form4, //参数设置
					productPartitionSettingsInsertDto: this.form5, //除颤分区设置
					parameterSettingInsertDto: this.form6, //本次随访测试数据vtfEventInsertDto
					parameterAdjustmentInsertDto: this.form7,
					vtfEventInsertDto: this.vtfIdData, //VT/VF
					patientsProductInfo: patientsProductInfo, //产品信息
					fileType: this.medicalNo ? this.fileType : "1",
					inspectionReportId: this.medicalData.id || this.inspectionReportId || this.form.inspectionReportId,
					type: this.typeVal == "新增" ? "1" : "2",

				}
				let res = await this.$Http.PatientsInfoFollowInsert(info)
				if (res.code == '200') {
					localStorage.removeItem("ocrName1");
					this.$router.push({
						path: '/PatientManagement'
					})
					this.$message.success(res.message);

				} else {
					this.$message.error(res.message);
				}
			},
			saves() {

				if (!this.form2.medicalNo) {
					return this.$message.error("请输入病例号！");
				}
				if(this.form3.ManufacturerModel.length<=0){
					return this.$message.error("请选择厂商/产品！");
				}
				if(!this.form3.productType){
					return this.$message.error("请输入类型！");
				}
				if(!this.form3.productSequenceNumber){
					return this.$message.error("请输入序列号！");
				}
				if(!this.form3.markingList){
					return this.$message.error("请输入电极型号！");
				}
				// if (this.errorCode !== "0") {
				// 	if (this.medicalNo) {
				// 		this.$confirm('文件预计处理时间为3-5分钟是否等待?', '提示', {
				// 			closeOnClickModal: false,
				// 			confirmButtonText: '等待',
				// 			cancelButtonText: '保存',
				// 			type: 'warning'
				// 		}).then(() => {
				// 			this.dialogVisible = false;
				// 			this.countdownVisible = true;
				// 			this.flag = setInterval(this.startTimer, 1000);
				// 			this.getCodeCount();
				// 		}).catch(() => {
				// 			this.dialogVisible = false;
				// 			this.QueryPatientsInfoFollowInsert();
				// 			this.$message({
				// 				type: 'info',
				// 				message: '已保存'
				// 			});
				// 		});
				// 	} else {
				// 		this.QueryPatientsInfoFollowInsert();
				// 	}

				// } else {
				// 	this.QueryPatientsInfoFollowInsert();
				// }
                 this.QueryPatientsInfoFollowInsert();
			},
			//保存
			DialogChangeVisibleSave() {
				this.$refs["ruleFormChange"].validate((valid) => {
					if (valid) {
						this.$refs['cascaderAddr']
						this.refSubCat = {
							hospitalId: this.ruleFormChange.ModelList[0],
							departmentsId: this.ruleFormChange.ModelList[1],
							userId: this.ruleFormChange.ModelList[2],
							hospitalName: this.$refs["refSubCat"].getCheckedNodes()[0].pathLabels[0],
							departmentsName: this.$refs["refSubCat"].getCheckedNodes()[0].pathLabels[1],
							userName: this.$refs["refSubCat"].getCheckedNodes()[0].pathLabels[2],
						}
						this.form2 = this.refSubCat;
						this.form2.medicalNo = this.$route.query.medicalNo;
						this.isFlag = false;
						this.DialogChangeVisible = false;
						this.form.followPerson=this.$refs["refSubCat"].getCheckedNodes()[0].pathLabels[2]
					} else {
						return false;
					}
				});
			},
			NewAdd(val, item,i) {
				this.typeVal = val;
				this.medicalData = JSON.parse(localStorage.getItem('medicalData'));
				if (val == "新增") {
					this.businessStatus = "1";
					this.form3 = {}; //产品信息
					this.form4 = {}; //参数设置
					this.form5 = {}; //除颤分区设置
					this.form6 = {}; //本次随访测试数据
					this.form7 = {}; //参数调整
					this.form = this.medicalData; //随访记录
					this.form2 = this.medicalData; //随访记录
					this.form2.Provinces = [this.medicalData.province, this.medicalData.city];
					let types = localStorage.getItem("type");
					//this.medicalData.specialDataFlag == "2" && 
					if (types == "200030") {
						this.DialogChangeVisible = true;
						this.ruleFormChange.ModelList = [this.medicalData.hospitalId, this.medicalData.departmentsId, this.medicalData.userId]
					} else {
						this.isFlag = false;
					}
					//if (this.medicalData.specialDataFlag == "2") {
						this.form.reportDocument = this.medicalData.inspectionReportName; //文件名称
						this.form3.productType = this.medicalData.productType; //产品类型
						//产品厂商manufacturerIdmanufacturerProductId
						if (this.medicalData.manufacturerId && this.medicalData.manufacturerProductId) {
							this.form3.ManufacturerModel = [this.medicalData.manufacturerId,this.medicalData.manufacturerProductId
							];
						} else {
							this.form3.ManufacturerModel = [this.medicalData.manufacturerId];
						}
						console.log(this.form3.ManufacturerModel, "ManufacturerModel")
						//文件路径
						this.oss = this.medicalData.inspectionReportUrl;
						//产品序列号
						this.form3.productSequenceNumber = this.medicalData.productSequenceNumber;
					//}
					

				}else {
					this.isFlag = false;
					this.businessStatus = item.businessStatus;
					//查询随访记录
					this.QueryPatientsFollowInfoBasic(item.dataCodeVice);


				}
			},
			cancle() {
				this.isFlag = true;
			},
			//分页组件
			pages(current, size) {
				//查询随访记录
				this.QueryPatientsFollowQueryPage();
			},
			//随访记录分页
			async QueryPatientsFollowQueryPage(current = 1, size = 10) {
				console.log(this.medicalNo, this.medicalData.hospitalId, "12344555")
				let info = {
					current: current,
					size: size,
					medicalNo: this.medicalNo,
					hospitalId: this.medicalData.hospitalId
				}
				//let obj = Object.assign({}, this.formInline, info);
				let res = await this.$Http.PatientsFollowQueryPage(info)
				if (res.code == '200') {
					this.PatientsFollowQueryPageData = res.data.data;
					this.total = res.data.total;
				} else {
					this.PatientsFollowQueryPageData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},
			//vt/vf类型
			async Queryupgrade() {
				let res = await this.$Http.DepartmentType({
					parentCode: "100014"
				});
				if (res.code == '200') {
					this.upgradeOptions = res.data;
				} else {
					this.$message.error(res.message);
				}
			},
		},
		data() {
			return {
				ManufacturerMarkingOptions:[],
				Height: document.documentElement.clientHeight - 300,
				oss: "",
				businessStatus: "",
				typeVal: "新增",
				types: localStorage.getItem("type"),
				fileType: "2",
				CurrencyAllOptions: [],
				ruleFormChange: {},
				rulesDelete: {
					ModelList: [{
						required: true,
						message: '请选择医院科室医生',
						trigger: 'change'
					}]
				},
				DialogChangeVisible: false,
				menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
				upgradeOptions: [],
				counts: 1,
				hour: 0,
				minutes: 0,
				seconds: 0,
				flag: null,
				countdownVisible: false,
				errorCode: "0",
				timer: null,
				ManufacturerModelOptions: [],
				SexOptions: [],
				medicalData: {},
				ProvincesOptions: [], //省市区
				leftVentricularVectorOptions: [], //左心室是否改变
				ventricleVip: [], //心室自身优先功能VIP
				leftAdaptacrtFunctionOptions: [], //adaptacrt功能打开
				leftMppFunctionOptions: [], //mpp功能打开
				medicalNo: "",
				fullName: "",
				phoneNumber: "",
				cpval: false,
				bcsfcssjval: false,
				ccfqszval: false,
				csszval: false,
				cstzval: false,
				vvsjval: false,
				furecords: false,
				total: 0,
				PatientsFollowQueryPageData: [],
				isFlag: true,
				//VT/VF时间
				vtfIdData: [],
				reportDisplay: false,
				fileList: [],
				// 上传体检报告模态框
				dialogVisible: false,
				//术者数据
				options4: [],
				options5: [],
				options6: [],
				//form对方记录
				form: {},
				//form对方记录表单验证规则
				rules: {},
				//患者基本信息
				form2: {
					Provinces: []
				},
				//form患者基本信息表单验证规则
				rules2: {},
				//form医疗信息
				form1: {},
				// form医疗信息表单验证规则
				rules1: {},
				//form产品信息
				form3: {
					ManufacturerModel: [],
					markingList:[]
				},
				// form产品信息表单验证规则
				rules3: {},
				// 基本参数
				form4: {},
				// form基本参数表单验证规则
				rules4: {},
				//分区设置
				form5: {},
				// form分区设置表单验证规则
				rules5: {},
				//参数设置
				form6: {},
				// form分区设置表单验证规则
				rules6: {},
				//参数调整
				form7: {},
				// form分区设置表单验证规则
				rules7: {},
				//性别数据
				ProductPackageData: [],
				inputValue: '',
				InventoryStatusData: [],
				fileStatus: "1",
				fileFile: "2"
			}
		},
		created() {},
		mounted() {
			this.fileFile = localStorage.getItem('fileFile');
			this.viewPdf();
			//获取路由传参的值
			this.medicalNo = this.$route.query.medicalNo;
			this.form2.medicalNo = this.$route.query.medicalNo;
			this.medicalData = JSON.parse(localStorage.getItem('medicalData'));
			console.log(this.medicalData.medicalNo)
			//产品厂商
			this.QueryManufacturerModel(4);
			
			//性别接口
			this.QuerySexType();
			//调取码值接口
			this.QueryCodeValue();
			//省市区
			this.QueryProvinces();

			//vt/vf
			this.Queryupgrade();
			//查询医院科室医生级联
			this.QueryCurrencyAll();

			if (this.medicalNo) {
				//查询随访记录
				this.QueryPatientsFollowQueryPage();
			} else {
				this.fileItem = JSON.parse(localStorage.getItem('fileItem')) || {};
				this.oss = this.fileItem.fileUrl;
				this.form.reportDocument = this.fileItem.fileName;
				this.businessStatus = "1";
				this.isFlag = false;

				if (this.fileFile == "2") {
					this.InspectionCurrencyList = JSON.parse(localStorage.getItem('InspectionCurrencyList'));
					this.ocrName = this.InspectionCurrencyList.ocrName;
					this.oss = this.InspectionCurrencyList.patientsProductInfo.inspectionReportUrl;
					this.local = this.InspectionCurrencyList.patientsProductInfo.inspectionReportLocalUrl;
					this.fileName = this.InspectionCurrencyList.patientsProductInfo.inspectionReportName;
					if (this.InspectionCurrencyList.productParametersBasicInsertDto) {
						this.form4 = this.InspectionCurrencyList.productParametersBasicInsertDto; //参数设置
					}
					if (this.InspectionCurrencyList.productPartitionSettingsInsertDto) {
						this.form5 = this.InspectionCurrencyList.productPartitionSettingsInsertDto; //除颤分区设置vtfEventInsertDto
					}
					if (this.InspectionCurrencyList.parameterSettingInsertDto) {
						this.form6 = this.InspectionCurrencyList.parameterSettingInsertDto; //本次随访测试数据parameterSettingInsertDto
					}
					if (this.InspectionCurrencyList.patientsFollowInsertDto) {
						this.form = this.InspectionCurrencyList.patientsFollowInsertDto; //随访记录
					}
					if (this.InspectionCurrencyList.vtfEventInsertDto) {
						this.vtfIdData = this.InspectionCurrencyList.vtfEventInsertDto; //VTvtfEventInsertDto
					}
					if (this.InspectionCurrencyList.patientsProductInfo) {
						this.form3 = this.InspectionCurrencyList.patientsProductInfo;
					}
					if (this.InspectionCurrencyList.patientsProductInfo.manufacturerId && this.InspectionCurrencyList.patientsProductInfo
						.manufacturerProductId) {
						this.form3.ManufacturerModel = [this.InspectionCurrencyList.patientsProductInfo.manufacturerId, this.InspectionCurrencyList
							.patientsProductInfo.manufacturerProductId
						];
					} else {
						this.form3.ManufacturerModel = [this.InspectionCurrencyList.patientsProductInfo.manufacturerId];
					}
					this.errorCode = this.InspectionCurrencyList.patientsProductInfo.errorCode;
					this.form2.medicalNo = this.InspectionCurrencyList.patientsFollowInsertDto.medicalNo;
				} else {
					this.form2.medicalNo = this.medicalData.medicalNo;
					this.fileItem = JSON.parse(localStorage.getItem('fileItem'));
					this.refSubCat = {
						departmentsId: this.fileItem.departmentsId,
						hospitalId: this.fileItem.hospitalId,
						userId: this.fileItem.doctorId
					}
				}

			}
		}
	}
</script>

<style scoped lang="scss">
	//伸缩
	.scaling {
		float: right;
		padding: 3px 0;
		color: #3DA2FF;

		i {
			color: #3DA2FF;
		}
	}

	.column_info {
		height: 40px;
		background: #E6F7FF;
		display: flex;
		align-items: center;
		padding-left: 10px;
		border: 1px solid #BAE7FF;
		margin-top: 10px;

		div {
			margin-right: 30px;
		}
	}

	//table样式
	.table-sty {
		width: 100%;
		color: #333333;
		text-align: center;
		font-size: 14px;
		border-collapse: collapse;
		margin-top: 16px;

		tr {
			td:nth-child(odd) {
				//奇数行
				background: #f5f2eb;
				width: 160px;
				padding: 10px 20px;
				border: 1px solid #ebeef5;
			}

			td:nth-child(even) {
				//偶数行
				background: #fff;
				width: 300px;
				padding: 10px 20px;
				border: 1px solid #ebeef5;
			}
		}
	}

	.margin_top {
		margin-top: 10px;
	}

	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}
</style>
